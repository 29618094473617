/**
 * @ComponentFor HorizontalScrollItemBlockViewModel
 */
import React from 'react';
import HorizontalScrollItemBlockViewModel from './HorizontalScrollItemBlockViewModel.type';
import { styled } from '@glitz/react';
import Image, { Ratio } from 'Shared/Image/Ratio';
import { black, white, minMediumMediaQuery } from 'Shared/Style';
import Link from 'Shared/Link';

const HorizontalScrollItemBlockViewModel = (props: HorizontalScrollItemBlockViewModel) => {
  const { text, imageUrl, linkUrl, imageAltText } = props;
  return (
    <Container to={linkUrl}>
      <ImageContainer>
        <ImageStyled src={imageUrl} ratio={Ratio.OneToOne} alt={imageAltText} />
      </ImageContainer>
      <Text>{text}</Text>
    </Container>
  );
};

export default HorizontalScrollItemBlockViewModel;

const Container = styled(Link, {
  backgroundColor: 'transparent',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: { right: 24 },
});
const ImageContainer = styled.div({
  backgroundColor: white,
  borderRadius: '100%',
  width: 110,
  margin: { bottom: 10 },
  [minMediumMediaQuery]: {
    width: 150,
  },
});
const Text = styled.div({ color: black, marginBottom: 10, fontWeight: 'bold', textAlign: 'center' });
const ImageStyled = styled(Image, { borderRadius: '100%' });
